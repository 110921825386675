import React from 'react';

function Footer() {
  return (
    <footer className='smallText' style={{textAlign: 'center', padding: '2vw 4vw 6vw',backgroundColor: '#ffffff', display:"flex", justifyContent:"space-between", borderTop:"solid, 1px black"}}>
      <p>Copyright@2024</p>
      <p>cindy_su_123@outlook.com</p>
      <p>Designed By Cindy Su</p>
    </footer>
  );
}

export default Footer;